export default {
  computed: {
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.commit("UPDATE_LOADING", val);
      }
    },
    notFound: {
      get() {
        return this.$store.state.notFound;
      },
      set(val) {
        this.$store.commit("UPDATE_NOT_FOUND", val);
      }
    },
    underDev: {
      get() {
        return this.$store.state.underDev;
      },
      set(val) {
        this.$store.commit("UPDATE_UNDER_DEV", val);
      }
    },
    hideAll: {
      get() {
        return this.$store.state.hideAll;
      },
      set(val) {
        this.$store.commit("UPDATE_HIDE_ALL", val);
      }
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
};
