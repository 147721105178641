<template>
  <div>
    <TitleBold center>
      {{ restorePass ? $t("user.resetPass.title") : $t("user.signIn.title") }}
    </TitleBold>
    <MiddleContainer>
      <div class="row">
        <div class="col col-12 col-md-4 offset-md-4">
          <sign-in-component change-route></sign-in-component>
        </div>
      </div>
    </MiddleContainer>
  </div>
</template>

<script>
import TitleBold from "../components/base/TitleBold";
import MiddleContainer from "../components/base/MiddleContainer";
import SignInComponent from "../components/auth/SignInComponent";

export default {
  name: "SignIn",
  components: {
    SignInComponent,
    MiddleContainer,
    TitleBold
  },
  computed: {
    restorePass: {
      get() {
        return this.$store.state.restorePass;
      },
      set(val) {
        this.$store.commit("UPDATE_RESTORE_PASS", val);
      }
    },
    nowPage() {
      return this.$route.name;
    }
  },
  mounted() {
    this.restorePass = this.nowPage === "ResetPass";
  },
  watch: {
    nowPage() {
      this.restorePass = this.nowPage === "ResetPass";
    }
  }
};
</script>
